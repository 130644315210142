<template>
    
    <div class="cbo-tabs-container">
        <v-tabs v-model="tabSelected">
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#todos" value="todos" id="todos">
                <div class="tab-text">{{ txt.tabSearch }}</div>
            </v-tab>
        </v-tabs>
        <div class="cbo-tabs-content">
            <v-tabs-items v-model="tabSelected">
                <v-tab-item :value="'todos'">
                    <CboSearch/>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CboTabs',
    components: {
        CboSearch: () => import('./CboSearch.vue')
    },
    data() {
        return {
            tabSelected: 0,
            txt: {
                tabSearch: "Pesquisa"
            }
        }
    }
}
</script>
<style scoped lang="scss">
@import './src/design/variables.scss';

.cbo-tabs-container {
    width: 100%;
    border: $border-width-hairline $border-default $neutral-color-low-dark;
    border-radius: $border-radius-sm;
    .cbo-tabs-content {
        background-color: $neutral-color-low-light;
    }
}
</style>
