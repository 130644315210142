var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cbo-tabs-container" },
    [
      _c(
        "v-tabs",
        {
          model: {
            value: _vm.tabSelected,
            callback: function ($$v) {
              _vm.tabSelected = $$v
            },
            expression: "tabSelected",
          },
        },
        [
          _c("v-tabs-slider"),
          _c(
            "v-tab",
            { attrs: { href: "#todos", value: "todos", id: "todos" } },
            [
              _c("div", { staticClass: "tab-text" }, [
                _vm._v(_vm._s(_vm.txt.tabSearch)),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "cbo-tabs-content" },
        [
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tabSelected,
                callback: function ($$v) {
                  _vm.tabSelected = $$v
                },
                expression: "tabSelected",
              },
            },
            [
              _c(
                "v-tab-item",
                { attrs: { value: "todos" } },
                [_c("CboSearch")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }